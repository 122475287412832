import React from 'react';
import styled from 'styled-components';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  > * + * {
    margin-top: .75rem;
  }
`;

const Preview = ({ url }) => (
  <Container>
    <p>Destinazione</p>
    <a href={url}>{url}</a>
  </Container>
);

export default Preview
