import { theme } from "../theme/theme";

export const Redirect = ({ url }) => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    console.log(`[Info] %cRedirecting to ${url}`, `color:${theme.accent}`)
    return <></>;
  }

  window.location = url;
}

export const RedirectHome = () => <Redirect url="http://antreem.com" />;
