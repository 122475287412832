import React from 'react';
import styled from 'styled-components';
import { theme } from '../theme/theme';

const Container = styled.footer`
  background: ${theme.secondary};
  padding: 1rem;
  font-size: .75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > * + * {
    margin-top: 0.5rem;
  }

  a {
    color: ${theme.text};
    
  }
`;

const Footer = () => {
  return (
    <Container>
      <div>Copyright {new Date().getFullYear()} Antreem - All rights reserved</div>
      <div>VAT, C.F. e Iscrizione al Registro delle Imprese di Bologna 03130760246, R.E.A. 296354 — <a href="//www.iubenda.com/privacy-policy/59565670" title="Privacy Policy" rel="noopener noreferrer">Privacy Policy</a></div>
    </Container>
  )
}

export default Footer
