import React from 'react'
import { Route } from 'wouter'
import { RedirectHome, Redirect } from './components/Redirect'
import GlobalStyle from './theme/GlobalStyle'

import { redirects } from './data/content.json';
import Preview from './components/Preview';
import Footer from './components/Footer';
import styled from 'styled-components';
import { theme } from './theme/theme';
import { ReactComponent as Logo } from './logo.svg';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;

  > *:first-child {
    flex: 1;
  }
`;

const Main = styled.main`
  max-width: 1024px;
  margin: auto;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  font-size: 1.25rem;

  svg {
    color: ${theme.accent};
    width: 20ch;

    padding: 2rem 0;
  }
`;

const App = () => (
  <Container>
    <GlobalStyle />
    <Main>
      <a href="http://antreem.com">
        <Logo />
      </a>

      <Route path="/">
        <RedirectHome />
      </Route>

      <Route path="/:shortLink">
        {({ shortLink }) => {
          const cleanShortLink = shortLink.endsWith("+") ? shortLink.slice(0, -1) : shortLink;
          const { url } = redirects.find(({ link }) => link === cleanShortLink) || {};

          if (!url) return <RedirectHome />
          if (shortLink.endsWith("+")) return <Preview url={url} />
          return <Redirect url={url} />
        }}
      </Route>
    </Main>
    <Footer />
  </Container>
);


export default App
